@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    /*　ボタン（緑テーマ） */
    .btn-primary {
        @apply rounded-lg bg-lime-800 py-2.5 text-center font-medium text-gray-50 hover:bg-lime-900 active:bg-lime-950 active:font-bold;
    }
    /*　ボタン（緑テーマ、無効状態） */
    .btn-primary-disabled {
        @apply rounded-lg bg-lime-950 py-2.5 text-center font-medium text-gray-50;
    }
    /* ボタン（グレーテーマ） */
    .btn-secondary {
        @apply rounded-lg bg-gray-400 py-2.5 text-center font-medium text-gray-50 hover:bg-gray-500 active:bg-gray-600 active:font-bold;
    }
    /* ボタン（白テーマ） */
    .btn-white {
        @apply rounded-lg bg-white py-2.5 text-center font-medium text-black ring-1 ring-lime-800 hover:bg-lime-100 active:bg-lime-200 active:font-bold active:outline-none active:ring-2;
    }
    /* ボタン（緑テーマ、白文字） */
    .btn-green-white {
        @apply rounded-lg bg-lime-600 px-5 py-2.5 text-center font-medium text-lime-50 ring-1 ring-lime-800 hover:bg-lime-100 hover:text-lime-600 active:bg-lime-200 active:font-bold active:outline-none active:ring-2;
    }
    /* 見出し１（ページトップに表示するテキスト） */
    .h1-common {
        @apply text-center text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl;
    }
    /** テキストボックス */
    .textbox-common {
        @apply block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-xs text-gray-900 sm:text-sm;
    }
    /** 文字リンク */
    .link-text {
        @apply cursor-pointer underline hover:text-lime-800 active:text-lime-950;
    }
    /** 文字リンク（青） */
    .link-text-blue {
        @apply text-blue-500 hover:text-blue-700 hover:underline;
    }
}

/** フェードイン */
.fadeIn {
    animation: fadeIn 1500ms linear both;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
